import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import {
	withRouter
} from 'react-router-dom';
// import FlatButton from '@material-ui/core/FlatButton';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
});


const ScheduleInfo = (props) => {
  // use axios to pull schedule information
}

class AddSchedule extends Component{

  constructor() {
    super();
    this.state = {
      year: '',
      games: 0
    }
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleSubmit = (event) => {
    //Make a network call somewhere
    event.preventDefault();
    axios.post('https://ib8xi62cbk.execute-api.us-east-1.amazonaws.com/presentation/schedule', {year: this.state.year, games: this.state.games},{ crossDomain: true }).then(res => {
    // axios.post('https://roc-server-dev.localtunnel.me/schedule', {year: this.state.year, games: this.state.games},{ crossDomain: true }).then((res, schedule) => {
      if(res.status === 200){
        this.props.history.push({pathname: '/editschedule/', state: {schedule: res.data.schedule}})
      }
    })
  }

  render() {
    return(
      <>
      <Typography
        component="h1"
        variant="h4"
        color="inherit"
        noWrap
      >
      Add Schedule
      </Typography>
      <Paper style={{ marginTop: '20px'}}>
        <form onSubmit={this.handleSubmit}>
          {/* <TextField floatingLabelText="ID Number" onChange={this.handleChange}></TextField> */}

          <FormControl>
            <TextField
              id="schedule-year"
              label="Year of Schedule"
              value={this.state.year}
              onChange={this.handleChange('year')}
              margin='normal'
              variant='outlined'
            />
          </FormControl>
          <br></br>

          <FormControl>
            <TextField
              id="num-home-games"
              label="# of Home Games"
              value={this.state.games}
              onChange={this.handleChange('games')}
              margin='normal'
              variant='outlined'
              type='number'
            />
          </FormControl>
          <br></br>
          <FormGroup>
            <FormControl>
              <Button label="Submit" type="submit">Next</Button>
            </FormControl>
          </FormGroup>
        </form>
      </Paper>
      </>
    );
  }
}

export default withStyles(styles)(withRouter((AddSchedule)));
