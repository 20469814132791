import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { mainListItems, secondaryListItems } from '../ListItems/listItems';
import { Switch, Route, withRouter } from 'react-router-dom'
import CardDrop from '../CardDrop/CardDrop';
import Schedule from '../Schedule/Schedule';
import EditSchedule from '../EditSchedule/EditSchedule';
import AddSchedule from '../AddSchedule/AddSchedule';
import Games from '../Games/Games';
import Opponents from '../Opponents/Opponents';
import AddOpponent from '../AddOpponent/AddOpponent';
import EditOpponent from '../EditOpponent/EditOpponent';
import CustomNotification from '../CustomNotification/CustomNotification';
import { Button } from '@material-ui/core';

import Callback from '../Callback/Callback';

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    textAlign: 'left',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto',
  },
  chartContainer: {
    marginLeft: -22,
  },
  tableContainer: {
    height: 320,
  },
  h5: {
    marginBottom: theme.spacing.unit * 2,
  },
});

class Dashboard extends React.Component {
  state = {
    open: false,
    auth: this.props.auth,
    logout: false,
  };

  async componentDidMount() {
    // if (this.props.location.pathname === '/callback') return;
    try {
      await this.state.auth.silentAuth();
      this.forceUpdate();
    } catch (err) {
      if (err.error !== 'login_required') console.log(err.error);
    }
  }

  userAuthButton = () => {
    console.log(this.state.auth.isAuthenticated());
    
    if (!this.state.auth.isAuthenticated()) {
      return (<Button variant="contained" onClick={this.login.bind(this)}>Log In</Button>)
    } else {
      return (
        <>
        <label className="mr-2 text-white">{this.state.auth.getProfile().name}</label>
        <Button variant="contained" onClick={this.logout.bind(this)}>Log Out</Button>
        </>
      );
    }
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  login() {
    this.state.auth.login();
  };

  logout() {
    this.state.auth.logout();
    this.forceUpdate();
  };

  routes = () => {
    if (!this.state.auth.isAuthenticated()) {
      return (
        <div><p>Please log in to view this page</p></div>
      )
    } else {
      return(
        <Switch>
          {/* <Route exact path='/dashboard' component={Home}/> */}
          <Route exact path='/' component={Schedule}></Route>
          <Route path='/carddrop' component={CardDrop}/>
          <Route path='/schedule' component={Schedule}/>
          <Route path='/addschedule' component={AddSchedule}/>
          <Route path='/editschedule' component={EditSchedule}/>
          <Route path='/schedule-games' component={Games}/>
          <Route path='/opponents' component={Opponents}/>
          <Route path='/addopponent' component={AddOpponent}/>
          <Route path='/editopponent' component={EditOpponent}/>
          <Route path='/customnotification' component={CustomNotification}/>
        </Switch>)
    };
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <CssBaseline />

        <Route exact path='/callback' render={() => (
          <Callback auth={this.state.auth}/>
        )}/>

        <AppBar
          position="absolute"
          className={classNames(classes.appBar, this.state.open && classes.appBarShift)}
        >
          <Toolbar disableGutters={!this.state.open} className={classes.toolbar}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classNames(
                classes.menuButton,
                this.state.open && classes.menuButtonHidden,
              )}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              ROC
            </Typography>

            {this.userAuthButton()}

          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
          }}
          open={this.state.open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={this.handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>{mainListItems}</List>
          <Divider />
          <List>{secondaryListItems}</List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />

          {this.routes()}

        </main>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Dashboard);
