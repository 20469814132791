import React, {Component} from 'react';

class Games extends Component {
  constructor(props){
    super(props);
    this.state = {
      games: props.location.state.games
    }
  }
  render(){
    return(
      <div>{this.state.games[0].team}</div>
    )
  }
} 

export default Games;