import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import {
	withRouter
} from 'react-router-dom';
// import FlatButton from '@material-ui/core/FlatButton';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '500px',
  },
});

class CustomNotification extends Component{
  constructor() {
    super();
    this.state = {
			notificationTitle: '',
      notificationBody: '',
    }
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleSubmit = (event) => {
    //Make a network call somewhere
    event.preventDefault();
		axios.post('https://ib8xi62cbk.execute-api.us-east-1.amazonaws.com/presentation/send-app-notification', {
      notificationTitle: this.state.notificationTitle,
      notificationBody: this.state.notificationBody
    }).then((res) => {
      if(res.data.status === 200){
        console.log(res.data);
				this.props.history.push({pathname: '/schedule/'})
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  render() {
		const { classes } = this.props;
    return(
      <>
      <Typography
        component="h1"
        variant="h4"
        color="inherit"
        noWrap
				align="left"
      >
      Custom Notification
      </Typography>
      <Paper style={{ marginTop: '20px'}}>
        <form onSubmit={this.handleSubmit}>
          {/* <TextField floatingLabelText="ID Number" onChange={this.handleChange}></TextField> */}

          <FormControl className={classes.textField}>
            <TextField
              id="notificationTitle"
              label="Title"
              value={this.state.notificationTitle}
              onChange={this.handleChange('notificationTitle')}
              margin='normal'
              variant='outlined'
            />
          </FormControl>
          <br></br>

          <FormControl className={classes.textField}>
            <TextField
              id="notificationBody"
              label="Body"
              value={this.state.notificationBody}
              onChange={this.handleChange('notificationBody')}
              margin='normal'
              variant='outlined'
            />
          </FormControl>
          <br></br>
          <FormGroup>
            <FormControl>
              <Button label="Submit" type="submit">Send Notification</Button>
            </FormControl>
          </FormGroup>
        </form>
      </Paper>
      </>
    );
  }
}

export default withStyles(styles)(withRouter((CustomNotification)));
