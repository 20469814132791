import React, {Component} from 'react';
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MaterialUIForm from 'react-material-ui-form'
import JssProvider from 'react-jss/lib/JssProvider';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from 'axios';
// import FlatButton from '@material-ui/core/FlatButton';

const styles = theme => ({
  root: {
     margin: '50px',
     backgroundColor: '#eeeeee',
     padding: '50px'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  year: {
    marginBottom:'20px',
    marginLeft: '0.5rem',
  },
  container: {
    width: '100%',
    display: 'inline-block'
  },
  quarter: {
    width: '25%',
  },
  button: {
    margin: theme.spacing.unit,
  },
  fab: {
    margin: theme.spacing.unit,
    float: 'right',
    marginTop: '-15px',
  },
});

class EditSchedule extends Component{


  constructor(props){
    super(props);
    this.state = {
      schedule: props.location.state.schedule,
      opponents: [],
    }
  }

  componentWillMount(){
    axios.get('https://ib8xi62cbk.execute-api.us-east-1.amazonaws.com/presentation/get-all-opponents').then(res => {
      let newArray = this.state.opponents.slice();
      for(var x of res.data.data){
        newArray.push(x)
      }
      this.setState({
        opponents: newArray
      })
    })
    console.log(this.state.opponents[0]);
  }

  deleteSchedule = (req, res) => {
      axios.post('https://ib8xi62cbk.execute-api.us-east-1.amazonaws.com/presentation/delete-schedule/' + this.state.schedule.year,{ crossDomain: true }).then((res) => {
        if(res.status === 200){
          console.log('schedule deleted');
          this.props.history.push('/schedule')
        }
      }).catch(err => {
        console.error(err)
    })
  }

  handleChangeTeam = (id, event) => {
    const schedule = this.state.schedule;
    schedule.games[id].team = event.target.value;

    for (let i = 0; i < this.state.opponents.length; i++) {
      if (this.state.opponents[i].awayTeamDisplayName == event.target.value) {
        schedule.games[id].awayTeamName = this.state.opponents[i].awayTeamName
      }
    }
    this.setState({schedule: schedule});
  }

  handleChangeDateTime = (id, event) => {
    const schedule = this.state.schedule;
    schedule.games[id].date = event.target.value;
    this.setState({schedule: schedule});
  }

  handleChangePrizeType = (id, event) => {
    const schedule = this.state.schedule;
    schedule.games[id].prizeType = event.target.value;
    this.setState({schedule: schedule});
  }

  handleChangePrizeDistributionFrequency = (id, event) => {
    const schedule = this.state.schedule;
    schedule.games[id].prizeDistributionFrequency = event.target.value;
    this.setState({schedule: schedule});
  }

  handleSubmit = (event) => {
      event.preventDefault();
      console.log(this.state.schedule);

      axios.post('https://ib8xi62cbk.execute-api.us-east-1.amazonaws.com/presentation/save-schedule', {schedule: this.state.schedule, crossDomain: true }).then((res) => {
        if(res.status === 200){
          console.log('schedule updated');
          this.props.history.push('/schedule')
        }
      }).catch(err => {
        console.error(err)
        })
  }

  createListOfGames = () => {
    let editGames = []

    for (let i = 0; i < this.state.schedule.games.length; i++) {
      editGames.push(
        <div className='container' style={{display: 'inline-block', width: '100%'}}>
        <h5
        style={{marginLeft: '10em', fontFamily: "Roboto", textAlign: 'left'}}
        >
        {'Game ' + (i + 1)}
        </h5>

          <div className='quarter' style={{display: 'inline-block', width: '20%', padding: '15px'}}>
            <TextField
              key={'team' + (i+1)}
              select
              label={'Opponent'}
              style={{ margin: 8 }}
              value={this.state.schedule.games[i].team}
              fullWidth
              onChange={this.handleChangeTeam.bind(this, i)}
              placeholder='Enter opponent here'
              margin="normal"
              // variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            >
            {this.state.opponents.map(opponent => (
              <MenuItem key={opponent.awayTeamName} value={opponent.awayTeamDisplayName}>
                {opponent.awayTeamDisplayName}
              </MenuItem>
            ))}
            </TextField>
          </div>

          <div className='quarter' style={{display: 'inline-block', width: '20%', padding: '15px'}}>
            <TextField
              key={'time' + (i+1)}
              label={'Date and Time'}
              style={{ margin: 8 }}
              value={this.state.schedule.games[i].date.replace('Z', '')}
              type='datetime-local'
              fullWidth
              onChange={this.handleChangeDateTime.bind(this, i)}
              placeholder='Enter opponent here'
              margin="normal"
              // variant="outlined"
              InputLabelProps={{
                shrink: true,
            }}
          />
          </div>
          <div className='quarter' style={{display: 'inline-block', width: '20%', padding: '15px'}}>
            <TextField
              key={'prize' + (i+1)}
              label={'Prize'}
              style={{ margin: 8 }}
              value={this.state.schedule.games[i].prizeType}
              fullWidth
              onChange={this.handleChangePrizeType.bind(this, i)}
              placeholder='Enter prize here'
              margin="normal"
              // variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className='quarter' style={{display: 'inline-block', width: '20%', padding: '15px'}}>
            <TextField
              key={'prizeFrequency' + (i+1)}
              label={'Prize Frequency'}
              style={{ margin: 8 }}
              value={this.state.schedule.games[i].prizeDistributionFrequency}
              type='Number'
              fullWidth
              onChange={this.handleChangePrizeDistributionFrequency.bind(this, i)}
              placeholder='Enter distribution frequency here'
              margin="normal"
              // variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>

      </div>

      )
    }
    return editGames
  }

  render() {
      const { classes } = this.props;
      return(
        <>
        <Typography
          component="h1"
          variant="h4"
          color="inherit"
          align="left"
          noWrap
        >
        Edit Schedule
        </Typography>
        <Paper className={classes.root}>
          <form onSubmit={this.handleSubmit}>
            <Typography className={classes.year}
              component="h4"
              variant="h6"
              color="inherit"
              align="left"
              noWrap
            >
            Currently editing the schedule for {this.state.schedule.year}
            </Typography>

            {this.createListOfGames()}

            <FormGroup>
              <FormControl>
                <Button label="Submit" type="submit" style={{marginBottom: '25px'}}>Save</Button>
              </FormControl>
            </FormGroup>
          </form>
          <Fab aria-label="Delete" className={classes.fab} onClick={this.deleteSchedule}>
            <DeleteIcon />
          </Fab>
        </Paper>
        </>
      );
  }
}

export default withStyles(styles)(EditSchedule);
