import React from 'react';
import ReactDOM from 'react-dom';
import Index from './pages/index';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store/index';

ReactDOM.render((
  <Provider store={store}>
    <BrowserRouter>
      <Index/>
    </BrowserRouter>
  </Provider>
), document.getElementById('root'));
