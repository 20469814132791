import React, {Component} from 'react';
import PropTypes, { nominalTypeHack } from 'prop-types';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button } from '@material-ui/core';
import axios from 'axios';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
    backgroundColor: '#eeeeee',
  },
  table: {
    minWidth: 700,
  },
  button: {
    size: 'small',
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    marginTop: '0px',
  },
  buttonTop: {
    size: 'small',
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    margin: '10px',
    display: 'inline-block',
    right: 0,
  },
  link: {
    variant: 'outlined',
    size: 'small',
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'right',
  },
});

class Opponent extends Component {
  constructor(){
    super();
    this.state = {
      opponents: []
    }
  }
  componentWillMount(){
    axios.get('https://ib8xi62cbk.execute-api.us-east-1.amazonaws.com/presentation/get-all-opponents').then(res => {
      let newArray = this.state.opponents.slice();
      for(var x of res.data.data){
        newArray.push(x)
      }
      this.setState({
        opponents: newArray
      })
      console.log(this.state.opponents);
    })
  }

  render(){
    const { classes } = this.props;
    return (
      <>
      <div className='wrapper' style={{textAlign:'right'}}>
        <Typography
        component="h1"
        variant="h4"
        color="inherit"
        align="left"
        noWrap
        >
        Manage Opponents
        </Typography>
        <Button variant='outlined'><Link to="/addopponent" className={classes.buttonTop}>Add New Opponent</Link>
        </Button>
      </div>
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="center">Opponent Display Name</TableCell>
              <TableCell align="center">iOS App Opponent Name</TableCell>
              <TableCell align="center">Edit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.opponents.map(row => (
              <TableRow key={row.awayTeamDisplayName}>
                <TableCell align="center">
                  {row.awayTeamDisplayName}
                </TableCell>
                <TableCell align="center">{row.awayTeamName}</TableCell>
                <TableCell align="center">
                  <Button className={classes.button}>
                    <Link to={{
                      // pathname: '/schedule-games/',
                      pathname: '/editopponent/',
                      state: {
                        // games: row.games
                        opponent: row
                      }
                    }} className={classes.link}>
                    Edit Opponent
                    </Link>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      </>
    );
  }
}

Opponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Opponent);
