import React, {Component} from 'react';
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MaterialUIForm from 'react-material-ui-form'
import JssProvider from 'react-jss/lib/JssProvider';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from 'axios';
// import FlatButton from '@material-ui/core/FlatButton';

const styles = theme => ({
  root: {
     margin: '50px',
     backgroundColor: '#eeeeee',
     padding: '50px'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  year: {
    marginBottom:'20px',
    marginLeft: '0.5rem',
  },
  container: {
    width: '100%',
    display: 'inline-block'
  },
  quarter: {
    width: '25%',
  },
  button: {
    margin: theme.spacing.unit,
  },
  fab: {
    margin: theme.spacing.unit,
    float: 'right',
    marginTop: '-15px',
  },
});

class EditOpponent extends Component{

  constructor(props){
    super(props);
    this.state = {
      opponent: props.location.state.opponent,
    }
    console.log(this.state.opponent);
  }

  deleteOpponent = (req, res) => {
      axios.post('https://ib8xi62cbk.execute-api.us-east-1.amazonaws.com/presentation/delete-opponent/' + this.state.opponent.awayTeamName,{ crossDomain: true }).then((res) => {
        if(res.status === 200){
          console.log('opponent deleted');
          this.props.history.push('/opponents')
        }
      }).catch(err => {
        console.error(err)
    })
  }

  handleChangeDisplayName = (event) => {
    const opponent = this.state.opponent;
    opponent.awayTeamDisplayName = event.target.value;
    this.setState({opponent: opponent});
  }

  handleChangeName = (event) => {
    const opponent = this.state.opponent;
    opponent.awayTeamName = event.target.value;
    this.setState({opponent: opponent});
  }

  handleSubmit = (event) => {
      event.preventDefault();
      console.log(this.state.opponent);

      axios.post('https://ib8xi62cbk.execute-api.us-east-1.amazonaws.com/presentation/save-opponent', {opponent: this.state.opponent, crossDomain: true }).then((res) => {
        if(res.status === 200){
          console.log('opponent updated');
          this.props.history.push('/opponents')
        }
      }).catch(err => {
        console.error(err)
        })
  }

  render() {
      const { classes } = this.props;
      return(
        <>
        <Typography
          component="h1"
          variant="h4"
          color="inherit"
          align="left"
          noWrap
        >
        Edit Schedule
        </Typography>
        <Paper className={classes.root}>
          <form onSubmit={this.handleSubmit}>
            <div className='half' style={{display: 'inline-block', width: '20%', padding: '15px'}}>
              <TextField
                key="away-team-display-name"
                label="Display Name"
                style={{ margin: 8 }}
                value={this.state.opponent.awayTeamDisplayName}
                fullWidth
                onChange={this.handleChangeDisplayName.bind(this)}
                placeholder='Away Team Display Name'
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className='half' style={{display: 'inline-block', width: '20%', padding: '15px'}}>
              <TextField
                key="away-team-name"
                label="iOS App Opponent Name"
                style={{ margin: 8 }}
                value={this.state.opponent.awayTeamName}
                fullWidth
                onChange={this.handleChangeName.bind(this)}
                placeholder='iOS App Opponent Name'
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>

            <FormGroup>
              <FormControl>
                <Button label="Submit" type="submit" style={{marginBottom: '25px'}}>Save</Button>
              </FormControl>
            </FormGroup>
          </form>
          <Fab aria-label="Delete" className={classes.fab} onClick={this.deleteOpponent}>
            <DeleteIcon />
          </Fab>
        </Paper>
        </>
      );
  }
}

export default withStyles(styles)(EditOpponent);
