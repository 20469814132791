import React, {Component} from 'react';
import PropTypes, { nominalTypeHack } from 'prop-types';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import SendIcon from '@material-ui/icons/Send';

import axios from 'axios';

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
    size: 'small',
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    marginTop: '0px',
  },
  input: {
    display: 'none',
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
    backgroundColor: '#eeeeee',
  },
  table: {
    minWidth: 700,
  },
  buttonTop: {
    size: 'small',
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    margin: '10px',
    marginRight: '25px',
    float: 'right',
  },
  link: {
    variant: 'outlined',
    size: 'small',
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row'
  },
});

const currentYear = new Date().getFullYear()

class CardDrop extends Component {
  constructor(){
    super();
    this.state = {
      initialized: null,
      currentDrop: null,
      loading: false,
      schedule: {},
      games: []
    }
    this.clickHandler = this.clickHandler.bind(this);
  }

  componentDidMount(){
    console.log(this.state.schedule.valueOf.length);
    this.setState({loading:true});
    axios.get('https://ib8xi62cbk.execute-api.us-east-1.amazonaws.com/presentation/schedule/' + currentYear).then(res => {
      const currentSchedule = res.data.result;

      this.setState({
        schedule: currentSchedule,
        games: res.data.result.games,
        loading: false
      })
      console.log(this.state.schedule.valueOf.length);
      console.log(this.state.games);
    })


    // axios.get('https://ib8xi62cbk.execute-api.us-east-1.amazonaws.com/Test/carddrop').then(res => {
    //   if(res.data.cardDrop.initialized){
    //     this.setState({
    //       initialized: res.data.cardDrop.initialized,
    //       currentDrop: res.data.cardDrop,
    //       loading: false
    //     })
    //   }
    //   this.setState({
    //     initialized: res.data.cardDrop.initialized,
    //     loading: false
    //   })
    // })
  }

  clickHandler(){
    this.setState({loading:true});
    axios.post('https://ib8xi62cbk.execute-api.us-east-1.amazonaws.com/Test/carddrop', {
      initialized: !this.state.initialized
    }).then((res) => {
      console.log(res)
      if(!this.state.initialized){
        this.setState({initialized: !this.state.initialized, currentDrop: res.data.cardDrop, loading: false});
      } else {
        this.setState({initialized: !this.state.initialized, currentDrop: null, loading: false})
      }

    })
  }

  dropInitialized(game){
    console.log(game)
    this.setState({loading: true});
    axios.post('https://ib8xi62cbk.execute-api.us-east-1.amazonaws.com/presentation/linecard/startdrop', {game: game, year: currentYear}).then((res) => {
      if(res.data.status === 200){
        console.log(res.data)
        let newGames = [...this.state.games]
        let newSchedule = this.state.schedule
        let gameMatch = newGames.findIndex((el) => {
          return el.gameId === res.data.game.gameId
        })
        newGames[gameMatch] = res.data.game
        let scheduleGameMatch = newSchedule.games.findIndex((el) => {
          return el.gameId === res.data.game.gameId
        })
        newSchedule.games[scheduleGameMatch] = res.data.game
        this.setState({
          games: newGames,
          schedule: newSchedule,
          loading: false
        })
      }
    }).then(() => {
      axios.post('https://ib8xi62cbk.execute-api.us-east-1.amazonaws.com/presentation/save-schedule', {schedule: this.state.schedule, crossDomain: true }).then((res) => {
        if(res.status === 200){
          console.log('schedule updated');
        }
      }).catch(err => {
        console.error(err)
        })
    })

    axios.post('https://ib8xi62cbk.execute-api.us-east-1.amazonaws.com/presentation/send-app-notification', {
      notificationTitle: 'Line Drop Active!',
      notificationBody: 'Request your digital line card now'
    }).then((res) => {
      if(res.data.status === 200){
        console.log(res.data);
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  stopCurrentDrop(game){
    this.setState({loading: true})
    axios.post('https://ib8xi62cbk.execute-api.us-east-1.amazonaws.com/presentation/linecard/stopdrop', {game: game, year: currentYear}).then((res) => {
      if(res.status === 200){
        let newGames = [...this.state.games]
        let newSchedule = this.state.schedule
        let gameMatch = newGames.findIndex((el) => {
          return el.gameId === res.data.game.gameId
        })
        newGames[gameMatch] = res.data.game
        let scheduleGameMatch = newSchedule.games.findIndex((el) => {
          return el.gameId === res.data.game.gameId
        })
        newSchedule.games[scheduleGameMatch] = res.data.game
        this.setState({
          games: newGames,
          schedule: newSchedule,
          loading: false
        })
      }
    }).then(() => {
      axios.post('https://ib8xi62cbk.execute-api.us-east-1.amazonaws.com/presentation/save-schedule', {schedule: this.state.schedule, crossDomain: true }).then((res) => {
        if(res.status === 200){
          console.log('schedule updated');
        }
      }).catch(err => {
        console.error(err)
        })
    })
  }



  render(){
    const { classes } = this.props;
    if(this.state.games === undefined) {
      return false;
    }
    return (
      <>
      <div className='wrapper'>
        <Typography
        component="h1"
        variant="h4"
        color="inherit"
        align="left"
        noWrap
        >
        Line Card Drop
        </Typography>
      </div>
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="center">Opponent</TableCell>
              <TableCell align="center">Date</TableCell>
              <TableCell align="center">Current Drop Status</TableCell>
              <TableCell align="center">Initiate Drop</TableCell>
              <TableCell align="center">Cancel Drop</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {this.state.games.map(row => (
            <TableRow key={row.gameId}>
              <TableCell align="center">
                {row.team}
              </TableCell>
              <TableCell align='center'>
                {row.date.slice(0, -6)}
              </TableCell>
              <TableCell align='center'>
                {row.cardDropInitialized ? 'Drop in progress' : 'No drop in Progress'}
              </TableCell>
              <TableCell align='center'>
                {row.cardDropInitialized ?
                ''
                :
                <Button
                  onClick={() => this.dropInitialized(row)}>
                  Send Drop <SendIcon className={styles.rightIcon} style={{marginLeft: '10px'}}></SendIcon>
                </Button>
                }
              </TableCell>
              <TableCell align='center'>
                {row.cardDropInitialized ?
                <Button
                  onClick={() => this.stopCurrentDrop(row)}>
                  Stop Drop <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8 0-1.85.63-3.55 1.69-4.9L16.9 18.31C15.55 19.37 13.85 20 12 20zm6.31-3.1L7.1 5.69C8.45 4.63 10.15 4 12 4c4.42 0 8 3.58 8 8 0 1.85-.63 3.55-1.69 4.9z"/></svg>
                </Button>
                :
                ''
                }
              </TableCell>
            </TableRow>
          ))}
          </TableBody>
        </Table>
      </Paper>
      </>
    );
  }
}

CardDrop.propTypes = {
  classes: PropTypes.object.isRequired,
};

//   {
//     console.log(this.state)
//     return(
//       <>
//         <Typography
//           component="h1"
//           variant="h4"
//           color="inherit"
//           noWrap
//         >
//           Line Card Drop
//         </Typography>
//         <br/>
//         <br/>
//         <Button variant="contained" color="primary" className={styles.button} size="large" onClick={this.clickHandler}>
//           {!this.state.initialized ? `Send Drop` : `Cancel Drop`}
//           <Icon className={styles.rightIcon} style={{marginLeft: '10px'}}>send</Icon>
//         </Button>
//         <br/>
//         <br/>
//         <Button variant="contained" color="primary" className={styles.button} size="large" onClick={this.checkDrop}>
//           Check Drop
//         </Button>
//         <br/>
//         <br/>
//         <Typography
//           component="h3"
//           variant="h6"
//           color="inherit"
//           noWrap
//         >
//           {this.state.loading ? `Loading...` : (this.state.initialized ? `Drop currently in progress...` : `No drop`)}
//         </Typography>
//       </>
//     );
//   }
// }

export default withStyles(styles)(CardDrop);
