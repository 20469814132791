import React from 'react';
import { Link } from "react-router-dom";
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ContactsIcon from '@material-ui/icons/Contacts';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import NotificationsIcon from '@material-ui/icons/Notifications';

export const mainListItems = (
  <div>
    {/* <ListItem button component={props => <Link {...props} to="/dashboard" />}>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItem> */}
    <ListItem button component={props => <Link {...props} to="/schedule" />}>
      <ListItemIcon>
        <CalendarTodayIcon />
      </ListItemIcon>
      <ListItemText primary="Schedules" />
    </ListItem>
    {/* <ListItem button component={props => <Link {...props} to="/reports" />}>
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Reports" />
    </ListItem> */}
    <ListItem button component={props => <Link {...props} to="/carddrop" />}>
      <ListItemIcon>
        <MailOutlineIcon />
      </ListItemIcon>
      <ListItemText primary="Line Card Drop" />
    </ListItem>
    <ListItem button component={props => <Link {...props} to="/customnotification" />}>
      <ListItemIcon>
        <NotificationsIcon />
      </ListItemIcon>
      <ListItemText primary="Custom Notifications" />
    </ListItem>
  </div>
);

export const secondaryListItems = (
  <div>
  <ListItem button component={props => <Link {...props} to="/opponents" />}>
    <ListItemIcon>
      <ContactsIcon />
    </ListItemIcon>
    <ListItemText primary="Opponents" />
  </ListItem>

  </div>
);
